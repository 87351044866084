<script setup lang="ts">
import type { SliceInterface, VoixColorFieldInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import Modal from '@/components/utilities/Modal.vue'

import 'swiper/css'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    bottomCopy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    linkAppearance: VoixSelectFieldInterface
    bannerText: VoixTextFieldInterface
    bannerColor: VoixColorFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Copy Card', group: 'Kimpton', layouts: ['Kimpton'] },
  description: 'Image on top with copy underneath on a white background.',
  preview: 'SlicesKimptonCopyCard.jpg',
  fields: {
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        default: {
          width: 920,
          height: 520,
        },
      },
    },

    title: {
      type: 'text',
      label: 'Title',
    },

    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },

    bottomCopy: {
      type: 'wysiwyg',
      label: 'Bottom Copy',
      enabled: false,
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: true,
      group: 'Link',
    },

    linkAppearance: {
      type: 'select',
      label: 'Link Appearance',
      options: {
        default: 'Default',
        button: 'Button',
      },
      default: 'default',
      group: 'Link',
    },

    bannerText: {
      type: 'text',
      label: 'Banner Text',
      enabled: false,
    },

    bannerColor: {
      type: 'color',
      label: 'Banner Color',
      default: '#37b3ea',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: {
        value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      title: {
        value: 'This is the Title',
      },
      copy: {
        value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus</p>',
      },
      link: {
        value: {
          text: 'Learn more',
          url: '#',
        },
      },
      bannerText: {
        value: 'New Property!',
        enabled: true,
      },
    },
  }],
})

// Templatizer Stuff
const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const templatizerGallery: any = [
  { media: { value: 'https://plus.unsplash.com/premium_photo-1670044658988-fca844bce315?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
  { media: { value: 'https://images.unsplash.com/photo-1719813916163-ada504fd9660?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
  { media: { value: 'https://images.unsplash.com/photo-1695128782705-e8a6a49ea5ed?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
]

// Gallery Stuff
const showGallery = ref(false)
const gallerySubSlices = props.slice.elements?.filter((element: any) => element.slot === 'gallery') as SliceInterface[]

let numberOfSlides = gallerySubSlices.length
if (numberOfSlides === 0 && isVoixTemplatizer) {
  numberOfSlides = templatizerGallery.length
}

const swiperRef: Ref<SwiperType | null> = ref(null)
function setSwiperRef(swiper: SwiperType) {
  swiperRef.value = swiper
}

function next() {
  if (swiperRef.value)
    swiperRef.value.slideNext()
}

function prev() {
  if (swiperRef.value)
    swiperRef.value.slidePrev()
}
</script>

<template>
  <div class="max-w-lg">
    <div class="relative aspect-video">
      <VoixMedia :field="fields.media" class="w-full h-full object-cover" />

      <!-- <button class="absolute top-4 right-4 bg-kimpton-secondary text-white rounded-full w-8 h-8 flex justify-center items-center outline-bonita-inverse" @click="showGallery = true">
        <Icon name="heroicons:camera-20-solid" class="w-4 h-4" />
      </button> -->

      <div
        v-if="fields.bannerText.enabled"
        class="absolute top-8 left-0 text-white font-bold uppercase text-xs px-2 py-2"
        :style="{ backgroundColor: fields.bannerColor.value }"
      >
        {{ fields.bannerText.value }}
      </div>
    </div>

    <div class="py-8 px-8  bg-white flex flex-col justify-between  border-b-2 border-kimpton-action ">
      <div>
        <h3 class="text-lg font-bold">
          {{ fields.title.value }}
        </h3>
        <VoixWysiwyg :field="fields.copy" class="mt-3 font-light" />
      </div>

      <div class="mt-12">
        <VoixWysiwyg :field="fields.bottomCopy" class="pb-5  font-light" />
        <div v-if="fields.link.enabled">
          <VoixLink v-if="fields.linkAppearance.value === 'default'" :field="fields.link" class="font-bold uppercase text-sm" />
          <VoixLink v-else :field="fields.link" class="text-center bg-kimpton-action text-white px-8 py-3" />
        </div>
      </div>
    </div>

    <ClientOnly>
      <Teleport to="#portal-destination">
        <Modal
          v-if="showGallery"
          key="features-gallery"
          @close="showGallery = false"
        >
          <div class="absolute inset-0 p-8 bg-kimpton-secondary w-full h-full ">
            <div class="aspect-video">
              <Swiper id="swiper" :modules="[Navigation, Pagination]" :slides-per-view="1" :space-between="0" class="border-4 border-white " @swiper="setSwiperRef">
                <SwiperSlide v-for="slice in gallerySubSlices" :key="slice.id">
                  <VoixSlice :slice="slice" />
                </SwiperSlide>
                <template v-if="isVoixTemplatizer">
                  <SwiperSlide v-for="(slide, key) in templatizerGallery" :key="key">
                    <SlicesBonitaFeatureGalleryImage :fields="slide" />
                  </SwiperSlide>
                </template>
              </Swiper>
            </div>
            <div class="aspect-[4/3] absolute top-0 left-0 right-0">
              <div class="absolute inset-0 ">
                <div
                  v-if="numberOfSlides > 1"
                  class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center p-4 px-8"
                >
                  <button class="pointer-events-auto" @click="prev">
                    <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-left-20-solid" />
                  </button>
                  <button class="pointer-events-auto" @click="next">
                    <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-right-20-solid" />
                  </button>
                </div>
                <div
                  v-if="numberOfSlides > 1"
                  class="absolute z-20 inset-0 flex justify-center items-end p-8 pointer-events-none"
                >
                  <div class="flex items-center space-x-2 pointer-events-auto">
                    <template
                      v-for="index in numberOfSlides"
                      :key="index"
                    >
                      <button
                        class=" rounded-full border border-white opacity-50 hover:opacity-100 duration-200"
                        :class="{
                          'bg-white border-0 w-1.5 h-1.5': swiperRef?.activeIndex !== index - 1,
                          'border-2 w-2 h-2': swiperRef?.activeIndex === index - 1,
                        }"
                        @click="swiperRef?.slideTo(index - 1)"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Teleport>
    </ClientOnly>
  </div>
</template>
